// If you want all of Bootstrap uncomment below
// import "bootstrap";
import { modal, collapse, button, tab, dropdown } from "bootstrap";

// import * as bootstrap from "bootstrap";
// If you want only specific bootstrap js files
// import "bootstrap/js/dist/dropdown";
// import 'bootstrap/js/dist/base-component';
// import "bootstrap/js/dist/button";
// import 'bootstrap/js/dist/carousel';
// import "bootstrap/js/dist/collapse";
// import "bootstrap/js/dist/modal";
// import 'bootstrap/js/dist/offcanvas';
// import "bootstrap/js/dist/popover";
// import 'bootstrap/js/dist/scrollspy';
// import "bootstrap/js/dist/tab";
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
